import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect } from "react";

const NotFound: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    navigate(`/${i18n.language === "en" ? "" : i18n.language}`);
  }, []);

  return null;
};

export default NotFound;
